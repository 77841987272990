import Vue from "vue";
import Vuex from "vuex";
import router from '@/router/index.js';
import {
  updateCart,
  CartList,
  removeCart
} from "api/product.js";
import {
  Message,
  MessageBox,
  Loading
} from "element-ui";
Vue.use(Vuex);

const state = {
  //登陆，注册头部显示隐藏  true显示  false隐藏 默认false
  flag: false,
  //登陆，注册组件区分  true登陆组件 false注册组件
  isLheader: false,
  //是否上传资质
  isqualification: true,
  memberName: '',

  selectedAll: false, //是否全选 默认全选
  normalSelected: false, //购物车-常规商品全选
  freeMailSelected: false, //购物车-小额包邮商品全选
  goodsFreeMail: [], //购物车-小额包邮商品列表
  goods: [], //购物车-常规商品列表
  carGoodsNum: 0, //购物车数量
};
const mutations = {
  memberName(state, params) {
    state.memberName = params;
  },
  //购物车-常规商品列表
  modifyGoods(state, params) {
    state.goods = params;
    // 关闭loading
    Loading.service().close();
  },
  //购物车-小额包邮商品列表
  modifyGoodsFreeMail(state, params) {
    state.goodsFreeMail = params;
    // 关闭loading
    Loading.service().close();
  },
  //购物车-常规商品全选
  goodsSelected(state) {
    state.normalSelected = !state.normalSelected;
    if (state.normalSelected) { //常规全选true
      if (state.goodsFreeMail.length > 0) {
        state.selectedAll = state.freeMailSelected
      } else {
        state.selectedAll = true
      }
    } else {
      state.selectedAll = false
    }
    state.goods.forEach((item) => {
      if (item.product.publishStatus) { //上架
        item.flag = state.normalSelected;
      }
    });
  },
  //购物车-小额包邮全选
  goodsFreeMailSelected(state) {
    state.freeMailSelected = !state.freeMailSelected;
    if (state.freeMailSelected) { //小额包邮全选true
      if (state.goodsFreeMail.length > 0) {
        state.selectedAll = state.normalSelected
      } else {
        state.selectedAll = true
      }
    } else {
      state.selectedAll = false
    }
    state.goodsFreeMail.forEach((item) => {
      if (item.product.publishStatus) { //上架
        item.flag = state.freeMailSelected;
      }
    });
  },
  // 购物车全选
  modifySelected(state) {
    state.selectedAll = !state.selectedAll;
    state.normalSelected = state.selectedAll;
    state.freeMailSelected = state.selectedAll;
    state.goods.forEach((item) => {
      if (item.product.publishStatus) {
        item.flag = state.selectedAll;
      }
    });
    state.goodsFreeMail.forEach((item) => {
      if (item.product.publishStatus) {
        item.flag = state.selectedAll;
      }
    });
  },
   //购物车-常规商品 勾选商品
   handleGoodsChange(state, index) {
    state.goods[index].flag = !state.goods[index].flag;
    var bStop = true;
    for (var i = 0; i < state.goods.length; i++) {
      if (!state.goods[i].flag) {
        bStop = false;
        break;
      }
    }
    state.normalSelected = bStop;
    if (state.normalSelected) { //常规全选true
      if (state.goodsFreeMail.length > 0) {
        state.selectedAll = state.freeMailSelected
      } else {
        state.selectedAll = true
      }
    } else {
      state.selectedAll = false
    }
  },
  //购物车-小额包邮 勾选商品
  handleGoodsFreeMailChange(state, index) {
    state.goodsFreeMail[index].flag = !state.goodsFreeMail[index].flag;
    var bStop = true;
    for (var i = 0; i < state.goodsFreeMail.length; i++) {
      if (!state.goodsFreeMail[i].flag) {
        bStop = false;
        break;
      }
    }
    state.freeMailSelected = bStop;
    if (state.freeMailSelected) { //小额包邮全选true
      if (state.goodsFreeMail.length > 0) {
        state.selectedAll = state.normalSelected
      } else {
        state.selectedAll = true
      }
    } else {
      state.selectedAll = false
    }
  },

  // 减
  handleReducer(state, index) {
    if (state.goods[index].quantity <= state.goods[index].product.minOrderNum) {
      state.goods[index].quantity = state.goods[index].product.minOrderNum;
      Message({
        message: "最小购买数量为" + state.goods[index].product.minOrderNum + "",
        type: "warning",
      });
    } else {
      state.goods[index].quantity =
        state.goods[index].quantity - state.goods[index].product.minOrderNum;

        // 如果存在阶梯价  start
        // console.log('初始价格', state.goods[index].product.memberPrice)
        if(state.goods[index].product.numPrice){
          // console.log('当前价格',state.goods[index].product.price)
          // console.log('当前数量',state.goods[index].quantity)
          let numPriceArr=JSON.parse(state.goods[index].product.numPrice);
          if(numPriceArr[0].num>state.goods[index].product.minOrderNum){
               if(state.goods[index].quantity<numPriceArr[0].num){
                  state.goods[index].product.price= state.goods[index].product.memberPrice
                  return false
               }else{
                  numPriceArr.forEach(item=>{
                    if(state.goods[index].quantity>=item.num){
                      // console.log('此时价格应该为',item.price)
                      state.goods[index].product.price=item.price
                      return false
                    }
                  })
               }
          }else{
            // console.log(numPriceArr)
            numPriceArr.forEach(item=>{
              if(state.goods[index].quantity>=item.num){
                // console.log('此时价格应该为',item.price)
                state.goods[index].product.price=item.price
                return false
              }
            })
          }
        }
        // 如果存在阶梯价  end




    }
    let datas = {
      quantity: state.goods[index].quantity,
      id: state.goods[index].id,
    };
    updateCart(datas).then(() => {});
  },
  // 减-小额包邮
  handleReducerFreeMail(state, index) {
    if (state.goodsFreeMail[index].quantity <= state.goodsFreeMail[index].product.minOrderNum) {
      state.goodsFreeMail[index].quantity = state.goodsFreeMail[index].product.minOrderNum;
      Message({
        message: "最小购买数量为" + state.goodsFreeMail[index].product.minOrderNum + "",
        type: "warning",
      });
    } else {
      state.goodsFreeMail[index].quantity =
        state.goodsFreeMail[index].quantity - state.goodsFreeMail[index].product.minOrderNum;
    }
    let datas = {
      quantity: state.goodsFreeMail[index].quantity,
      id: state.goodsFreeMail[index].id,
    };
    updateCart(datas).then(() => {});
  },
  //加
  handleAdd(state, index) {

    if(state.goods[index].product.isLimit==1){
      //限购商品处理 console.log( state.goods[index].product.stockNum)
      state.goods[index].product.stockTotal = state.goods[index].product.stockNum > state.goods[index].purchaseLimitationNum?state.goods[index].purchaseLimitationNum:state.goods[index].product.stockNum
    }
    if (Number(state.goods[index].product.stockTotal) == 0) {
      state.goods[index].quantity = Number(state.goods[index].product.minOrderNum);
      Message({
        message: "该商品暂无库存",
      });
    } else {
      if (state.goods[index].quantity > state.goods[index].product.stockTotal) {
        state.goods[index].quantity = Number(Number(state.goods[index].product.stockTotal) - Number(state.goods[index].product.stockTotal) % Number(state.goods[index].product.minOrderNum));
        Message({
          message: "不能超过最大购买数量",
        });
      } else {
        state.goods[index].quantity += state.goods[index].product.minOrderNum;

// 如果存在阶梯价  start
if(state.goods[index].product.numPrice){
  let numPriceArr=JSON.parse(state.goods[index].product.numPrice);
  if(numPriceArr[0].num>state.goods[index].product.minOrderNum){
       if(state.goods[index].quantity<numPriceArr[0].num){
          state.goods[index].product.price= state.goods[index].product.memberPrice
          return false
       }else{
          numPriceArr.forEach(item=>{
            if(state.goods[index].quantity>=item.num){
              state.goods[index].product.price=item.price
              return false
            }
          })
       }
  }else{
    numPriceArr.forEach(item=>{
      if(state.goods[index].quantity>=item.num){
        state.goods[index].product.price=item.price
        return false
      }
    })
  }
}
// 如果存在阶梯价  end

        if (state.goods[index].quantity > state.goods[index].product.stockTotal) {
          if (Number(state.goods[index].quantity) % Number(state.goods[index].product.minOrderNum) === 0) {
            state.goods[index].quantity -= state.goods[index].product.minOrderNum;
          } else {
            state.goods[index].quantity = Number(Number(state.goods[index].product.stockTotal) - Number(state.goods[index].quantity) % Number(state.goods[index].product.minOrderNum));
          }
          Message({
            message: "不能超过最大购买数量",
          });
        }
      }
    }
    let datas = {
      quantity: state.goods[index].quantity,
      id: state.goods[index].id,
    };
    updateCart(datas).then(() => {});
  },
  //加-小额包邮
  handleAddFreeMail(state, index) {
    if (Number(state.goodsFreeMail[index].product.stockTotal) == 0) {
      state.goodsFreeMail[index].quantity = Number(state.goodsFreeMail[index].product.minOrderNum);
      Message({
        message: "该商品暂无库存",
      });
    } else {
      if (state.goodsFreeMail[index].quantity > state.goodsFreeMail[index].product.stockTotal) {
        state.goodsFreeMail[index].quantity = Number(Number(state.goodsFreeMail[index].product.stockTotal) - Number(state.goodsFreeMail[index].product.stockTotal) % Number(state.goodsFreeMail[index].product.minOrderNum));
        Message({
          message: "不能超过最大购买数量",
        });
      } else {
        state.goodsFreeMail[index].quantity += state.goodsFreeMail[index].product.minOrderNum;
        if (state.goodsFreeMail[index].quantity > state.goodsFreeMail[index].product.stockTotal) {
          if (Number(state.goodsFreeMail[index].quantity) % Number(state.goodsFreeMail[index].product.minOrderNum) === 0) {
            state.goodsFreeMail[index].quantity -= state.goodsFreeMail[index].product.minOrderNum;
          } else {
            state.goodsFreeMail[index].quantity = Number(Number(state.goodsFreeMail[index].product.stockTotal) - Number(state.goodsFreeMail[index].quantity) % Number(state.goodsFreeMail[index].product.minOrderNum));
          }
          Message({
            message: "不能超过最大购买数量",
          });
        }
      }
    }
    let datas = {
      quantity: state.goodsFreeMail[index].quantity,
      id: state.goodsFreeMail[index].id,
    };
    updateCart(datas).then(() => {});
  },

  //修改设置购物车数量
  setCarGoodsNum(state, params) {
    if (isNaN(params)) {
      state.carGoodsNum = Number(params.initsum);
    } else {
      state.carGoodsNum = state.carGoodsNum + Number(params);
    }
    // state.CarGoodsNum=params
  },
  //改变input
  handleChangValue(state, index) {
    state.goods[index].quantity = state.goods[index].quantity.replace(/\D/g, '')
    if (Number(state.goods[index].product.stockTotal) == 0) {
      state.goods[index].quantity = Number(state.goods[index].product.minOrderNum);
      Message({
        message: "该商品暂无库存",
      });
    } else {

      if(state.goods[index].product.isLimit==1){
        //限购商品处理 console.log( state.goods[index].product.stockNum)
        state.goods[index].product.stockTotal = state.goods[index].product.stockNum > state.goods[index].purchaseLimitationNum?state.goods[index].purchaseLimitationNum:state.goods[index].product.stockNum
      }

      if (state.goods[index].quantity > state.goods[index].product.stockTotal) {
        state.goods[index].quantity = Number(Number(state.goods[index].product.stockTotal) - Number(state.goods[index].product.stockTotal) % Number(state.goods[index].product.minOrderNum));
        Message({
          message: "不能超过最大购买数量",
        });
      } else if (state.goods[index].quantity <= state.goods[index].product.minOrderNum) {
        state.goods[index].quantity = state.goods[index].product.minOrderNum;
        Message({
          message: "最小购买数量为" + state.goods[index].product.minOrderNum + "",
          type: "warning",
        });
      } else {
        if (Number(state.goods[index].quantity) % Number(state.goods[index].product.minOrderNum) === 0) {
          state.goods[index].quantity = Number(state.goods[index].quantity);
        } else {
          state.goods[index].quantity = Number(state.goods[index].quantity) - Number(state.goods[index].quantity) % Number(state.goods[index].product.minOrderNum);
        }
      }

// 如果存在阶梯价  start
if(state.goods[index].product.numPrice){
  let numPriceArr=JSON.parse(state.goods[index].product.numPrice);
  if(numPriceArr[0].num>state.goods[index].product.minOrderNum){
       if(state.goods[index].quantity<numPriceArr[0].num){
          state.goods[index].product.price= state.goods[index].product.memberPrice
          return false
       }else{
          numPriceArr.forEach(item=>{
            if(state.goods[index].quantity>=item.num){
              state.goods[index].product.price=item.price
              return false
            }
          })
       }
  }else{
    numPriceArr.forEach(item=>{
      if(state.goods[index].quantity>=item.num){
        state.goods[index].product.price=item.price
        return false
      }
    })
  }
}
// 如果存在阶梯价  end


    }


    let datas = {
      quantity: state.goods[index].quantity,
      id: state.goods[index].id,
    };
    updateCart(datas).then(() => {});
  },
  //改变input-小额包邮
  handleChangValueFreeMail(state, index) {
    state.goodsFreeMail[index].quantity = state.goodsFreeMail[index].quantity.replace(/\D/g, '')
    if (Number(state.goodsFreeMail[index].product.stockTotal) == 0) {
      state.goodsFreeMail[index].quantity = Number(state.goodsFreeMail[index].product.minOrderNum);
      Message({
        message: "该商品暂无库存",
      });
    } else {
      if (state.goodsFreeMail[index].quantity > state.goodsFreeMail[index].product.stockTotal) {
        state.goodsFreeMail[index].quantity = Number(Number(state.goodsFreeMail[index].product.stockTotal) - Number(state.goodsFreeMail[index].product.stockTotal) % Number(state.goodsFreeMail[index].product.minOrderNum));
        Message({
          message: "不能超过最大购买数量",
        });
      } else if (state.goodsFreeMail[index].quantity <= state.goodsFreeMail[index].product.minOrderNum) {
        state.goodsFreeMail[index].quantity = state.goodsFreeMail[index].product.minOrderNum;
        Message({
          message: "最小购买数量为" + state.goodsFreeMail[index].product.minOrderNum + "",
          type: "warning",
        });
      } else {
        if (Number(state.goodsFreeMail[index].quantity) % Number(state.goodsFreeMail[index].product.minOrderNum) === 0) {
          state.goodsFreeMail[index].quantity = Number(state.goodsFreeMail[index].quantity);
        } else {
          state.goodsFreeMail[index].quantity = Number(state.goodsFreeMail[index].quantity) - Number(state.goodsFreeMail[index].quantity) % Number(state.goodsFreeMail[index].product.minOrderNum);
        }
      }
    }


    let datas = {
      quantity: state.goodsFreeMail[index].quantity,
      id: state.goodsFreeMail[index].id,
    };
    updateCart(datas).then(() => {});
  },
  //单个商品移除
  handleDel(state, params) {
    state.goods.splice(params, 1);
  },
  //单个商品移除-小额包邮
  handleDelFreeMail(state, params) {
    state.goodsFreeMail.splice(params, 1);
  },
  //删除多个商品
  handleMoreDel(state) {
    let datas = {
      size: 2000
    }
    CartList(datas).then(
      res => {
        res.data.records.forEach((item) => {
          item.flag = false;
        });
        state.selectedAll = false
        state.normalSelected = false
        state.freeMailSelected = false
        let result = res.data.records

        // 分离成常规和小额包邮
        let arr = []
        let arrFree = []
        for (let item of result) {
          if (item.cartType === 3 || item.cartType === '3') { //小额包邮
            arrFree.push(item)
          } else {
            arr.push(item)
          }
        }
        state.goods = arr;
        state.goodsFreeMail = arrFree;
      }
    )
  },
  //清空购物车
  handleAllDel(state) {
    state.goods = [];
    state.goodsFreeMail = []
  },
  //切换登陆页面头部显示隐藏  true显示  false隐藏
  changeFlag(state, params) {
    state.flag = params;
  },
  isHeader(state, params) {
    state.isLheader = params;
  },

};
const actions = {
  async getGoods({
    commit
  }) {
    // 显示loading   
    Loading.service({
      fullscreen: true,
      background: '#ffffff45'
    })
    let datas = {
      size: 2000
    }
    let data = await CartList(datas);
    if (data.data.records != undefined) {
      //给每一条数据都加一个状态值
      data.data.records.forEach((item) => {
        item.flag = false;
      });
      state.selectedAll = false
      state.normalSelected = false
      state.freeMailSelected = false
      let result = data.data.records
      console.log('购物车: ', result);
      let sum = result.reduce(function (prev, cur) {
        return cur.quantity + prev;
      }, 0);
      state.carGoodsNum = sum

      // 分离成常规和小额包邮
      let arr = []
      let arrFree = []
      for (let item of result) {
        if (item.cartType === 3 || item.cartType === '3') { //小额包邮
          arrFree.push(item)
        } else {
          arr.push(item)
        }
      }

      commit("modifyGoods", arr);
      commit("modifyGoodsFreeMail", arrFree);

    } else {
      Message({
        type: "warning",
        message: data.msg,
      });
      // 关闭loading
      Loading.service().close();
      router.push({
        path: '/home'
      })
    }

  },
  //清空购物车
  handleAllDel(state) {
    let moreIds = "";
    for (var i = 0; i < state.state.goods.length; i++) {
      let id = state.state.goods[i].id;
      moreIds += id + ",";
    }
    for (let item of state.state.goodsFreeMail) {
      moreIds += item.id + ",";
    }
    MessageBox.confirm("您确定要清空购物车吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let ids = {
          ids: moreIds
        };
        // 删除多个商品接口
        removeCart(ids).then((data) => {
          if (data.data.code == 200) {
            Message({
              type: "success",
              message: "删除成功!",
            });
            // // 删除成功刷新购物车数量
            this.commit("handleAllDel");
          } else {
            Message({
              type: "warning",
              message: "删除失败!",
            });
          }
        });
      })
      .catch(() => {
        Message({
          type: "info",
          message: "已取消删除",
        });
      });
  },
  //删除多个商品
  handleMoreDel(state) {
    let moreIds = "";
    let arr = state.state.goods.concat(state.state.goodsFreeMail)
    for (let item of arr) {
      if (item.flag == true || item.flag == 'true') {
        moreIds += item.id
      }
    }
    if (moreIds == '') {
      Message({
        message: "请先勾选商品",
      });
    } else {
      MessageBox.confirm("您确定要删除这些商品吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let ids = {
            ids: moreIds
          };
          // 删除多个商品接口
          removeCart(ids).then((data) => {
            if (data.data.code == 200) {
              Message({
                type: "success",
                message: "删除成功!",
              });
              // // 删除成功刷新购物车数量
              this.commit("handleMoreDel");
            } else {
              Message({
                type: "warning",
                message: "删除失败!",
              });
            }
          });
        })
        .catch(() => {
          Message({
            type: "info",
            message: "已取消删除",
          });
        });
    }

  },
  //删除单个商品
  handleDel(state, index) {
    MessageBox.confirm("您确定要删除该商品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let ids = {
          ids: state.state.goods[index].id
        };
        // (ids);
        // 删除单个商品接口
        removeCart(ids).then((data) => {
          if (data.data.code == 200) {
            Message({
              type: "success",
              message: "删除成功!",
            });
            this.commit("handleDel", index);
          } else {
            Message({
              type: "warning",
              message: "删除失败!",
            });
          }
        });
      })
      .catch(() => {
        Message({
          type: "info",
          message: "已取消删除",
        });
      });
  },
  //删除单个商品-小额包邮
  handleDelFreeMail(state, index) {
    MessageBox.confirm("您确定要删除该商品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        let ids = {
          ids: state.state.goodsFreeMail[index].id
        };
        // (ids);
        // 删除单个商品接口
        removeCart(ids).then((data) => {
          if (data.data.code == 200) {
            Message({
              type: "success",
              message: "删除成功!",
            });
            this.commit("handleDelFreeMail", index);
          } else {
            Message({
              type: "warning",
              message: "删除失败!",
            });
          }
        });
      })
      .catch(() => {
        Message({
          type: "info",
          message: "已取消删除",
        });
      });
  },
};
const getters = {
  memberNameFun: (state) => {
    return state.memberName;
  },
  carGoodsNum: (state) => {
    return state.carGoodsNum;
  },
  //购物车商品总数量，选中的商品数量、价格总数
  goodsPrice(state) {
   // 选中的未购商品数量    isNoBuyNum=0;
    // 
    let goodsCount = 0,isNoBuyNum=0,
      goodsPriceSum = 0;
    state.carGoodsNum = 0;
    if (state.goods && state.goods.length) {
      for (let i = 0; i < state.goods.length; i++) {
        state.carGoodsNum += state.goods[i].quantity;
        if (state.goods[i].flag) {
          goodsCount += state.goods[i].quantity;
          goodsPriceSum +=
            state.goods[i].quantity * state.goods[i].product.price * 100;
            if(state.goods[i].isNoBuy==1){
              isNoBuyNum+=1;
            }

        }
      }
    }
    if (state.goodsFreeMail && state.goodsFreeMail.length) {
      for (let item of state.goodsFreeMail) {
        state.carGoodsNum += item.quantity
        if (item.flag) {
          goodsCount += item.quantity
          goodsPriceSum += item.quantity * item.product.price * 100
          if(item.isNoBuy==1){
            isNoBuyNum+=1;
          }

        }
      }
    }
    goodsPriceSum = (goodsPriceSum / 100).toFixed(2); //还原，并格式化，且转为字符串
    return {
      isNoBuyNum,
      goodsCount,
      goodsPriceSum,
    };
  },
  typeQuantity(state) {
    let goodsQuantity = 0;
    for (var i = 0; i < state.goods.length; i++) {
      if (state.goods[i].flag) {
        goodsQuantity += 1;
      }
    }
    for (let item of state.goodsFreeMail) {
      if (item.flag) {
        goodsQuantity += 1;
      }
    }
    return goodsQuantity;
  },
};
const Store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
export default Store;