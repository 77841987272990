const TokenKey = 'token';
//获取token
export const getToken=()=>{
    return sessionStorage.getItem(TokenKey)
}
//设置token
export const setToken=(token)=>{
    return sessionStorage.setItem(TokenKey, token)
}
//删除token
export const removeToken=()=>{
    return sessionStorage.removeItem(TokenKey)
}


export const setLocalToken=(token)=>{
    return localStorage.setItem(TokenKey, token)
}
export const getLocalToken=()=>{
    return localStorage.getItem(TokenKey)
}
export const removeLocalToken=()=>{
    return localStorage.removeItem(TokenKey)
}

// 重写setItem事件，当使用setItem的时候，触发，window.dispatchEvent派发事件
function dispatchEventStroage() {
    const signSetItem = localStorage.setItem
    localStorage.setItem = function(key, val) {
        let setEvent = new Event('setItemEvent')
        setEvent.key = key
        setEvent.newValue = val
        window.dispatchEvent(setEvent)
        signSetItem.apply(this, arguments)
    }
}

export default dispatchEventStroage;
