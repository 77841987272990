import axios from 'axios'
// import {getToken} from "utils/session.js"
import {getLocalToken} from "utils/session.js"
import { Message ,Loading } from "element-ui";
import router from '@/router/index.js';
// 请求拦截器
axios.interceptors.request.use(function (config) {
  // let token = getToken();
  let token = getLocalToken()
  if (token) {
     config.headers['Blade-Auth'] = token;
   }
  return config;
}, function (error) {
  return Promise.reject(error);
});
//响应拦截器
axios.interceptors.response.use(function (response) {
    // 如果code是-1，说明用户已注销或者token已过期
    if (response.status == 200) {
      const data = response.data;
      if (data.code == -1) {
        // router.push({path: "/login"});
      }
    }
  return response;
}, function (err) {
        if (err.response.status === 500) { 
          Loading.service().close();
        }
        // 未授权
      if (err.response.status === 401) { 
        Message({
          message:err.response.data.msg,
          type: "warning",
        });
        Loading.service().close();
        router.push({path: "/login"});
        return 
      }
      if(err.response.status===400){
        return Promise.resolve(err.response);
      }else{
        return Promise.reject(err);
      }
});
export default axios